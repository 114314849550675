.navbar{
    background-color: #061c44;
    top: -1px;
}
.navbar a{
    color: #8EB2F6;
}
.navbar a:hover, .navbar a:focus{
    color: white;
    text-decoration: solid 1px underline;
}
.navbar-toggler-icon{
    background-image: url("../bars-solid.svg");
}