#landing{
  height: calc(100vh - 56px);
  background-color: #061c44;
  color: white;
}
#landing h1{
  font-size: 4rem;
}
#landing hr{
  height: 2px;
  width: 70%;
}
#landing h1 span{
  font-size: 2.4rem;
}
#about img{
  max-width: 60%;
  max-height: 444px;
}
#about svg{
    width:40px;
    color:#061c44;
    transition: 0.2s;
    margin: 5px 0;
}
#about svg:hover{
    color:#0d6efd;
    margin: 2px 0 8px 0
}
#skills ul{
  list-style: none;
  padding-left: 0;
}
#projects h3 a{
  text-decoration: none;
  color: #212529
}
#projects h3 a:hover, #projects h3 a:focus{
  text-decoration: underline;
  color: #212529
}
hr.headerUnderline{
  width: 15%;
  height: 4px;
  border: none;
  color: #8ACB88;
  opacity: 1;
  background-color: #8ACB88;
  margin-bottom: 20px;
  margin-top: 5px;
}
.py-7{
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.dark{
  background-color: #061c4414
}